<!--<template>-->
<!--  <p v-if="timeOut">-->
<!--    {{ getConfig.countdown.text || "开跑倒计时" }}-->
<!--    <span>{{ countDown.days }}</span>天-->
<!--    <span>{{ countDown.hours }}</span>时-->
<!--    <span>{{ countDown.minutes }}</span>分-->
<!--    <span>{{ countDown.seconds }}</span>秒-->
<!--  </p>-->
<!--</template>-->

<!--<script>-->
<!--  import { mapActions, mapGetters, mapMutations } from "vuex";-->
<!--  export default {-->
<!--  name: "countDown",-->
<!--  props:["time"],-->
<!--  data() {-->
<!--    return {-->
<!--      timeOut: true,-->
<!--      countDown: {-->
<!--        days: "00",-->
<!--        hours: "00",-->
<!--        minutes: "00",-->
<!--        seconds: "00"-->
<!--      }-->
<!--    };-->
<!--  },-->
<!--  created() {-->
<!--    this.getCountDown(this.time);-->
<!--  },-->
<!--  computed:{-->
<!--    ...mapGetters([-->
<!--      "getConfig",-->
<!--    ]),-->
<!--  },-->
<!--  methods: {-->
<!--    getCountDown(time) {-->
<!--      let leftTime = new Date(time) - new Date();-->
<!--      if (leftTime <= 0) {-->
<!--        this.timeOut = false;-->
<!--        return false;-->
<!--      }-->
<!--      let days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10);-->
<!--      let hours = parseInt((leftTime / 1000 / 60 / 60) % 24, 10);-->
<!--      let minutes = parseInt((leftTime / 1000 / 60) % 60, 10);-->
<!--      let seconds = parseInt((leftTime / 1000) % 60, 10);-->
<!--      let obj = {-->
<!--        days: this.checkTime(days),-->
<!--        hours: this.checkTime(hours),-->
<!--        minutes: this.checkTime(minutes),-->
<!--        seconds: this.checkTime(seconds)-->
<!--      };-->
<!--      this.countDown = obj;-->
<!--      this.countDown = Object.assign({}, this.countDown, obj);-->
<!--      setTimeout(() => this.getCountDown(time), 1000);-->
<!--    },-->
<!--    checkTime(i) {-->
<!--      if (i < 10) {-->
<!--        i = "0" + i;-->
<!--      }-->
<!--      return i;-->
<!--    }-->
<!--  }-->
<!--};-->
<!--</script>-->
<template>
  <div v-if="timeOut" class="timeOut">
    <div v-if="type==1" class="count_down1">
      <span>{{ getConfig.countdown.text || "距离开跑还有" }}</span>
      <span>{{ countDown.days }}</span>天
      <span>{{ countDown.hours }}</span>时
      <span>{{ countDown.minutes }}</span>分
      <span>{{ countDown.seconds }}</span>秒
    </div>
    <div v-else-if="type==2" class="count_down">
      <div
          style="font-size:16px;font-weight:bold;margin:10px 0;"
      >{{ getConfig.countdown.text || "开跑倒计时" }}</div>
      <div class="count_down_flex">
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.days1 }}</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.days2 }}</div>
        <div class="count_down2_box2" :style="`background:${fontBgColor}`">天</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.hours1 }}</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.hours2 }}</div>
        <div class="count_down2_box2" :style="`background:${fontBgColor}`">时</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.minutes1 }}</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.minutes2 }}</div>
        <div class="count_down2_box2" :style="`background:${fontBgColor}`">分</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.seconds1 }}</div>
        <div class="count_down2_box1" :style="`background:${fontBgColor}`">{{ countDown.seconds2 }}</div>
      </div>
    </div>
    <div v-else-if="type==3" class="count_down" :style="`color:${fontColor}`">
      <div class="count_down_flex">
        <div
            style="font-size:16px;font-weight:bold;margin:10px 0;"
        >{{ getConfig.countdown.text || "开跑倒计时" }}</div>
        <div>
          <div style="font-size:18px;font-weight:bold;">{{ countDown.days }}</div>
          <div>天/day</div>
        </div>
        <div class="count_down3_sx" :style="`background:${fontColor}`"></div>
        <div>
          <div style="font-size:18px;font-weight:bold;">{{ countDown.hours }}</div>
          <div>时/hours</div>
        </div>
        <div class="count_down3_sx" :style="`background:${fontColor}`"></div>
        <div>
          <div style="font-size:18px;font-weight:bold;">{{ countDown.minutes }}</div>
          <div>分/min</div>
        </div>
        <div class="count_down3_sx" :style="`background:${fontColor}`"></div>
        <div>
          <div style="font-size:18px;font-weight:bold;">{{ countDown.seconds }}</div>
          <div>秒/sec</div>
        </div>
      </div>
    </div>
    <div v-else-if="type==4" class="count_down" :style="`color:${fontColor}`">
      <div
          style="font-size:16px;font-weight:bold;margin:10px 0;"
      >{{ getConfig.countdown.text || "开跑倒计时" }}</div>
      <div class="count_down_flex">
        <div class="count_down4_box1">
          <div class="count_down4_box2">{{ countDown.days }}</div>
        </div>
        <div>天</div>
        <div class="count_down4_box1">
          <div class="count_down4_box2">{{ countDown.hours }}</div>
        </div>
        <div>时</div>
        <div class="count_down4_box1">
          <div class="count_down4_box2">{{ countDown.minutes }}</div>
        </div>
        <div>分</div>
        <div class="count_down4_box1">
          <div class="count_down4_box2">{{ countDown.seconds }}</div>
        </div>
      </div>
    </div>
    <div v-else class="count_down_default" :style="`color:${fontColor};`">
      <div v-if="warp==1">
        {{text}}
      </div>
      <span v-else style="margin-right:10px"> {{text}}</span>
      <span>{{ countDown.days }}</span>天
      <span>{{ countDown.hours }}</span>时
      <span>{{ countDown.minutes }}</span>分
      <span>{{ countDown.seconds }}</span>秒
    </div>
    <!--<p v-if="time === 'noData'">请在右边进行【倒计时配置】</p>-->
  </div>
</template>

<script>
import { mapGetters} from "vuex";
export default {
  name: "countDown",
  props: ["time", "type", "fontColor", "fontBgColor",'text','warp'],
  // props: {
  //   time: {
  //     type: ""
  //   },
  //   type: ""
  // },
  computed: {
    ...mapGetters(["getConfig", "getLangType"])
  },
  data() {
    return {
      timeOut: true,
      countDown: {
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
      },
      timer: null,
      options: {
        digit: 100,
        label: false,
        countdown: true,
        clockFace: "DailyCounter"
      }
    };
  },
  mounted() {
    this.getCountDown(this.time);
  },
  methods: {
    getCountDown(t) {
      let time = t;
      if (t === "noData") {
        time = new Date().getTime() + 700000000;
      }
      let leftTime = new Date(time) - new Date();
      if (leftTime <= 0) {
        this.timeOut = false;
        let obj = {
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00"
        };
        return false;
      }
      // this.options.digit = leftTime / 1000;
      this.options = {
        digit: leftTime / 1000,
        label: false,
        countdown: true,
        clockFace: "DailyCounter"
      };
      let days = parseInt(leftTime / 1000 / 60 / 60 / 24, 10);
      let hours = parseInt((leftTime / 1000 / 60 / 60) % 24, 10);
      let minutes = parseInt((leftTime / 1000 / 60) % 60, 10);
      let seconds = parseInt((leftTime / 1000) % 60, 10);
      let obj = {
        days: this.checkTime(days),
        hours: this.checkTime(hours),
        minutes: this.checkTime(minutes),
        seconds: this.checkTime(seconds)
      };
      if (this.type == 2) {
        obj.days1 = obj.days.toString().substr(0, 1);
        obj.days2 = obj.days.toString().substr(1, 1);
        obj.hours1 = obj.hours.toString().substr(0, 1);
        obj.hours2 = obj.hours.toString().substr(1, 1);
        obj.minutes1 = obj.minutes.toString().substr(0, 1);
        obj.minutes2 = obj.minutes.toString().substr(1, 1);
        obj.seconds1 = obj.seconds.toString().substr(0, 1);
        obj.seconds2 = obj.seconds.toString().substr(1, 1);
      }
      this.countDown = obj;
      this.countDown = Object.assign({}, this.countDown, obj);
      setTimeout(() => this.getCountDown(time), 1000);
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
  }
  // beforeDestroy() {
  //   clearInterval(this.timer);
  //   this.timer = null;
  // }
};
</script>
<style>
.count_down1 {
  width: 100%;
  /* background: #B44934;- */
  color: #ffffff;
  text-align: center;
  padding: 10px;
}
.count_down {
  width: 100%;
  padding: 10px;
}
.count_down_flex {
  display: flex;
  width: 450px;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}
.count_down4_box1 {
  width: 60px;
  height: 60px;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
}
.count_down4_box2 {
  width: 50px;
  position: relative;
  top: 4px;
  height: 50px;
  margin: 0 auto;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  border: 1px dotted rgba(255, 255, 255, 1);
  border-radius: 50%;
}
.count_down3_sx{
  width: 1px ;
  position: relative;
  bottom: 10px;
  height: 20px;
}
.count_down2_box1 {
  width: 36px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-radius: 4px;
}
.count_down2_box2 {
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
}
</style>

