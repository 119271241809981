let config = {
  name:"",
  ico:"",
  pageColor: "",
  langType: false,
  welCome: {
    config: {},
    enConfig: {}
  },
  topBanner: {},
  enTopBanner: {},
  navList: [],
  navConfig:{},
  enNavList: [],
  bannerConfig: {},
  countdown: {
    switch: false,
    time: "",
    offset: ""
  },
  bannerList: [],
  enBannerList: [],
  adList1: [],
  enAdList1: [],
  adList2: [],
  enAdList2: [],
  atlList: [],
  album: [],
  videoType: [],
  videos: [],
  coverAtlas:"",
  coverVideos:"",
  sponsorList: [],
  footer: {
    icon: [],
    items: []
  },
  footerItems:[],
  footerIcons:[],
  footerConfig: {},
  coverArticle:[],
  enCoverArticle:[],
  articleTypeList: [],
  articleList: [],
};
export default config;
