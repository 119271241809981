import axios from "axios";
import qs from "qs";
// import { Message } from 'iview';

axios.defaults.timeout = 5000;
// axios.defaults.baseURL = "http://47.107.239.142:8009";
// axios.defaults.baseURL = "http://192.168.1.119:8086"; // 测试环境
// axios.defaults.baseURL = "http://36.139.81.151:8130"; // 测试环境
// axios.defaults.baseURL = "http://test.platform.api.huodongzhongguo.com"; // 测试环境
// axios.defaults.baseURL = "https://test-gateway.huodongzhongguo.com/website"; // 测试环境
axios.defaults.baseURL = "https://gateway.huodongzhongguo.com/website"; // 线上环境
// axios.defaults.baseURL = "http://m170623b35.iok.la"; // 测试环境
// axios.defaults.headers.post["Content-Type"] = 'form-data';

//http request 拦截器
axios.interceptors.request.use(
  config => {
    // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
    // config.data = JSON.stringify(config.data);
    config.headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    // if(token){
    //   config.params = {'token':token}
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
//http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(data), {headers: {'Content-Type':'application/x-www-form-urlencoded'}}).then(
      response => {
          resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}
/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
