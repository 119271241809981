import Vue from "vue";
import Vuex from "vuex";
import { post,fetch } from "./api/api";
import config from "./webConfig";
import  {Base64} from 'js-base64';
import axios from "axios"

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    config: config,
    spinType: false
  },
  mutations: {},
  getters: {
    getConfig: state => state.config,
    getSpinType: state => state.spinType
  },
  actions: {
    configFrom({ state }) {
      state.spinType = true;
      // let origin = "http://chengdutest1.huodongzhongguo.com"  // 测试
      let origin =window.location.origin 
      origin=origin.replace('https','http')
      let base = Base64.encode(origin)
      // post("/open/website/getWebsiteJson", {
      //   post("/open/website/initialize/json", {
      //   url: window.location.origin
      fetch(`/open/website/initialize/json/${base}`).then(res => {
      // axios.get(`/open/website/initialize/json/${base}`).then(res => {
        // let siteJson = res.data.data !== null ? res.data.data : {};
        let siteJson = res.data !== null ? res.data : {};
        let initConfig = JSON.parse(JSON.stringify(config));
        for (let i in initConfig) {
          if (siteJson[i]) {
            initConfig[i] = typeof  initConfig[i] === "string" ? siteJson[i] : JSON.parse(siteJson[i]);
          }
          // if (i === "pageColor"){
          //   let arr = ["#5B62C9","#1B95ED","#00B38A","#1C2B42","#E74F46"];
          //   if(typeof parseInt(siteJson[i]) === "number" ) {
          //     console.log(3333)
          //   }
          //   console.log( initConfig[i])
          // }
        }
        state.config = initConfig;
        state.spinType = false;
      });
    }
  }
});
