import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/pc",
      name: "pc",
      component: () => import( './views/pc/header/'),
      // redirect: "/pc/home",
      children: [
        {
          path: "home",
          name: "pcHome",
          component: () => import( './views/pc/home/'),
        },
        {
          path: "articleList",
          name: "list",
          meta: {
            name: "文章列表"
          },
          component: () => import( './views/pc/article/list.vue'),
        },
        {
          path: "altsList",
          name: "alts",
          meta: {
            name: "图片列表"
          },
          component: () => import( './views/pc/article/alts.vue'),
        },
        {
          path: "videos",
          name: "videos",
          meta: {
            name: "视频列表"
          },
          component: () => import( './views/pc/article/alts.vue'),
        },
        {
          path: "articleCon",
          name: "article",
          meta: {
            name: "文章详情"
          },
          component: () => import( './views/pc/article/article.vue'),
        }
      ]
    },
    {
      path: "/wap",
      name: "wap",
      component: () => import( './views/wap/header/'),
      children: [
        {
          path: "home",
          name: "wapHome",
          component: () => import( './views/wap/home/'),
        },
        {
          path: "articleList",
          name: "wapList",
          meta: {
            name: "文章列表"
          },
          component: () => import( './views/wap/list/'),
        },
        {
          path: "articleCon",
          name: "wapArticle",
          meta: {
            name: "文章详情"
          },
          component: () => import( './views/wap/article/'),
        },
        {
          path: "altsList",
          name: "wapAlts",
          meta: {
            name: "图片列表"
          },
          component: () => import( './views/wap/fileList/'),
        },
        {
          path: "videos",
          name: "wapVideos",
          meta: {
            name: "视频列表"
          },
          component: () => import( './views/wap/fileList/'),
        },
      ]
    }
  ]
});
